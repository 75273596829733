import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    clientes: null,
    cliente: [],
    clientePage: []
  },
  mutations: {
    set_accion_clientes (state, clientes) {
      state.clientes = clientes
    },
    set_accion_cliente (state, cliente) {
      state.cliente = cliente
    },
    set_accion_clientePage (state, clientePage) {
      state.clientePage = clientePage
    }
  },
  actions: {
    async getAllClientes ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/cliente/all`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getAllClientes')
          // console.log(response.data)
          commit('set_accion_clientes', response.data)
          return true
        } else {
          console.log('por else getAllClientes')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getAllClientes')
        router.push({ path: '/' })
        return false
      }
    },
    async getAllClientesPage ({ commit, dispatch, state }, page) {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/cliente/allpage?page=${page.page}&size=${page.size}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api getAllClientesPage')
          // console.log(response.data)
          commit('set_accion_clientePage', response.data)
          return true
        } else {
          console.log('por else getAllClientesPage')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getAllClientesPage')
        router.push({ path: '/' })
        return false
      }
    },
    async getAllClientesPageByRazonSocial ({ commit, dispatch, state }, page) {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/cliente/filter?page=${page.page}&size=${page.size}&razon_social=${page.filter}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getAllClientesPageByRazonSocial')
          console.log(response.data)
          commit('set_accion_clientePage', response.data)
          return true
        } else {
          console.log('por else getAllClientesPageByRazonSocial')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getAllClientesPageByRazonSocial')
        router.push({ path: '/' })
        return false
      }
    },
    async getAllClientesPageByNroClienteSap ({ commit, dispatch, state }, page) {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/cliente/filter/nro-cliente-sap?page=${page.page}&size=${page.size}&nro_cliente_sap=${page.filter}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getAllClientesPageByNroClienteSap')
          console.log(response.data)
          commit('set_accion_clientePage', response.data)
          return true
        } else {
          console.log('por else getAllClientesPageByNroClienteSap')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getAllClientesPageByNroClienteSap')
        router.push({ path: '/' })
        return false
      }
    },
    async insertClientesSincro ({ commit, dispatch, state }, clientes) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/cliente/insert`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: clientes
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api insertClientesSincro')
          console.log(response.data)
          commit('set_accion_clientes', response.data)
          return true
        } else {
          console.log('por else insertClientesSincro')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch insertClientesSincro')
        router.push({ path: '/' })
        return false
      }
    },
    async insertClientes ({ commit, dispatch, state }, clientes) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/cliente/insertAsync`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: clientes
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api insertClientes')
          console.log(response.data)
          commit('set_accion_clientes', response.data)
          return true
        } else {
          console.log('por else insertClientes')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch insertClientes')
        router.push({ path: '/' })
        return false
      }
    },
    async deleteCliente ({ commit, dispatch, state }, idCliente) {
      try {
        const response = await axios({
          method: 'delete',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/cliente/delete/${idCliente}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api deleteCliente')
          console.log(response.data)
          commit('set_accion_clientes', response.data)
          return true
        } else {
          console.log('por else deleteCliente')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch deleteCliente')
        router.push({ path: '/' })
        return false
      }
    },
    async getClienteById ({ commit, dispatch, state }, idCliente) {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/cliente/get/${idCliente}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getClienteById')
          // console.log(response.data)
          commit('set_accion_cliente', response.data)
          return true
        } else {
          console.log('por else getClienteById')
        }
        return false
      } catch (err) {
        console.error(err)
        // console.log('por catch getClienteById')
        // router.push({ path: '/' })
        return false
      }
    },
    async updateClienteFoco ({ commit, dispatch, state }, clientes) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/cliente/updateClienteFoco`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: clientes
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api updateClienteFoco')
          console.log(response.data)
          commit('set_accion_clientes', response.data)
          return true
        } else {
          console.log('por else updateClienteFoco')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateClienteFoco')
        router.push({ path: '/' })
        return false
      }
    },
    async getUrlPresignedClientes ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/cliente/urlForinsertAsync`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getUrlPresignedClientes')
          console.log(response.data)
          return response.data
        } else {
          console.log('por else getUrlPresignedClientes')
        }
        return ''
      } catch (err) {
        console.error(err)
        console.log('por catch getUrlPresignedClientes')
        router.push({ path: '/' })
        return ''
      }
    }
  },
  getters: {
  }
}
