import { mapActions, mapState } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
import FiltroReportesView from '@/components/Layout/Filtros/Filtro_reportes/FiltroReportesView.vue'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'ReportesGestionesView',
  components: {
    FiltroReportesView
  },
  props: {
  },
  data: function () {
    return {
      filtro: null,
      items: [],
      itemsAux: [],
      fields: [
        { key: 'trans_cliente', label: 'Transorte-Cliente', sortable: true, sortDirection: 'desc', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'cliente_sap', label: 'Nro Cliente', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'fecha_ruta', label: 'Fecha Ruta', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'fecha_gestion', label: 'Fecha Gestion', sortable: true, sortDirection: 'desc', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'razon_social', label: 'Razon  Social', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'telefono_cliente', label: 'Telefono cliente', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nro_cliente', label: 'Nro Cliente', sortable: true, sortDirection: 'desc', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'territorio', label: 'Territorio', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nro_pedido', label: 'Nro Pedido', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'camion', label: 'Camion', sortable: true, sortDirection: 'desc', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'flota', label: 'Flota', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'conductor', label: 'Conductor', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'motivo_no_entrega', label: 'Motivo no entrega', sortable: true, sortDirection: 'desc', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'hora', label: 'Hora', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'comentarios', label: 'Comentarios', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'gestion', label: 'Gestion', sortable: true, sortDirection: 'desc', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'estado_final', label: 'Estado Final', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'vuelta', label: 'Vuelta', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'cd', label: 'CD', sortable: true, sortDirection: 'desc', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'unidades', label: 'Unidades', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'cajas', label: 'Cajas', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'pack', label: 'Pack', sortable: true, sortDirection: 'desc', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'botellas', label: 'Botellas', sortable: true, sortDirection: 'desc', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'canal', label: 'Canal', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'responsable', label: 'Responsable', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'analista', label: 'Analista', sortable: true, sortDirection: 'desc', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'uc', label: 'UC', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'atencion_local', label: 'Atencion Local', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'llegada', label: 'Llegada', sortable: true, sortDirection: 'desc', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'descarga', label: 'Descarga', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'final_entraga', label: 'Final Entrega', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'direccion', label: 'Direccion', sortable: true, sortDirection: 'desc', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'comuna', label: 'Comuna', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'duracion_gestion', label: 'Duracion Gestion', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      fechaDesde: '',
      fechaHasta: '',
      spinner: false
    }
  },
  watch: {
    filtro: function (val) {
      // console.log(this.filtro)
      if (this.filtro !== null && this.filtro.periodos !== null) {
        let desde = ''
        let hasta = ''
        const date = new Date()
        if (this.filtro.periodos.desde !== null && this.filtro.periodos.desde !== '') {
          desde = this.filtro.periodos.desde + ' 00:00:00'
        } else {
          const fechaActualDesde = `${
            date.getFullYear().toString().padStart(4, '0')}-${
              (date.getMonth() + 1).toString().padStart(2, '0')}-${
                date.getDate().toString().padStart(2, '0')} ${
                  '00'}:${
                    '00'}:${
                      '00'}`
          desde = fechaActualDesde
        }
        if (this.filtro.periodos.hasta !== null && this.filtro.periodos.hasta !== '') {
          hasta = this.filtro.periodos.hasta + ' 23:59:59'
        } else {
          const fechaActualHasta = `${
            date.getFullYear().toString().padStart(4, '0')}-${
              (date.getMonth() + 1).toString().padStart(2, '0')}-${
                date.getDate().toString().padStart(2, '0')} ${
                  date.getHours().toString().padStart(2, '0')}:${
                    date.getMinutes().toString().padStart(2, '0')}:${
                      date.getSeconds().toString().padStart(2, '0')}`
          hasta = fechaActualHasta
        }
        const filtro = {
          // periodo: {
          //   desde: desde,
          //   hasta: hasta
          // }
          report_type: 'gestiones',
          from: desde,
          until: hasta
        }
        this.fechaDesde = desde
        this.fechaHasta = hasta
        // console.log(filtro)
        this.spinner = true
        this.getReporteGestion(filtro).then((res) => {
          this.spinner = false
          // console.log(this.reporteGestion)
          this.items = this.reporteGestion
          // this.exportarExcel()
          this.totalRows = this.items.length
        })
      }
    }
  },
  mounted () {
    const date = new Date()
    const fechaActualDesde = `${
      date.getFullYear().toString().padStart(4, '0')}-${
        (date.getMonth() + 1).toString().padStart(2, '0')}-${
          date.getDate().toString().padStart(2, '0')} ${
            '00'}:${
              '00'}:${
                '00'}`
    const fechaActualHasta = `${
      date.getFullYear().toString().padStart(4, '0')}-${
        (date.getMonth() + 1).toString().padStart(2, '0')}-${
          date.getDate().toString().padStart(2, '0')} ${
            date.getHours().toString().padStart(2, '0')}:${
              date.getMinutes().toString().padStart(2, '0')}:${
                date.getSeconds().toString().padStart(2, '0')}`
    const filtro = {
      // periodo: {
      //   desde: fechaActualDesde,
      //   hasta: fechaActualHasta
      // }
      report_type: 'gestiones',
      from: fechaActualDesde,
      until: fechaActualHasta
    }
    this.fechaDesde = fechaActualDesde
    this.fechaHasta = fechaActualHasta
    console.log(filtro)
    this.spinner = true
    this.getReporteGestion(filtro).then((res) => {
      this.spinner = false
      this.items = this.reporteGestion
      this.totalRows = this.items.length
    })
  },
  methods: {
    ...mapActions('Reportes', [
      'getReporteGestion'
    ]),
    gererarReporteGestion () {
      const filtro = {
        // periodo: {
        //   desde: this.fechaDesde,
        //   hasta: this.fechaHasta
        // }
        report_type: 'gestiones',
        from: this.fechaDesde,
        until: this.fechaHasta
      }
      // console.log(filtro)
      this.spinner = true
      this.getReporteGestion(filtro).then((res) => {
        this.spinner = false
        console.log(this.reporteGestion)
        this.items = this.reporteGestion
        this.exportarExcel()
      })
    },
    exportarExcel () {
      console.log('exportar excel')
      // XLSX.writeFile(wb, 'cumplimiento.xlsx')
      let items = []
      this.itemsAux = []
      for (const item in this.items) {
        items = {}
        for (const [key, value] of Object.entries(this.items[item])) {
          if (!key.includes('_cellVariants')) {
            items[key] = value
          }
        }
        this.itemsAux.push(items)
      }
      const ws = XLSX.utils.json_to_sheet(this.itemsAux)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'plantilla')
      XLSX.writeFile(wb, 'gestiones.xlsx')
    },
    info (item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
  computed: {
    ...mapState('Reportes', ['reporteGestion']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
