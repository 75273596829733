import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'LoginView',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      usuario: '',
      password: ''
    }
  },
  watch: {
  },
  mounted () {
    this.getUpApi().then((res) => {
      console.log('start up cold api' + res)
    })
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones'
    ]),
    ...mapActions('EntregaDiaria', [
      'getUpApi'
    ]),
    ...mapActions([
      'GetToken'
    ]),
    login () {
      // console.log('login')
      const credenciales = { usernameOrEmail: this.usuario, password: this.password }
      this.GetToken(credenciales).then((res) => {
        // console.log(res)
        // console.log('cargado GetToken')
        if (!res) {
          this.$bvToast.toast('Usuario o Clave incorrectas', {
            title: 'Fallo Inicio de sesion',
            variant: 'danger',
            solid: true
          })
        }
      })
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes'])
  }
}
