import Vue from 'vue'
import Vuex from 'vuex'
import EntregaDiaria from '@/modules/EntregaDiaria.js'
import DocumentosPedido from '@/modules/DocumentosPedido.js'
import Comentarios from '@/modules/Comentarios.js'
import Clientes from '@/modules/Clientes.js'
import MotivoNoEntrega from '@/modules/MotivoNoEntrega.js'
import Reportes from '@/modules/Reportes.js'
import store from '@/store'
import axios from 'axios'
import router from '@/router/'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // host: 'http://localhost:8090',
    // hostGateway: 'http://localhost:8090',
    // host: 'https://app.bitacora.v2.dmetrix.cl/api',
    host: 'https://api.appdmetrix.com',
    hostGateway: 'https://app.bitacora.v2.dmetrix.cl',
    user: '',
    password: '',
    token: '',
    imagenUsuario: '',
    perfilUsuario: {},
    usuarios: [],
    usuariosUpdate: [],
    periodoEntregaDiaria: null,
    motivosNoEntrega: [
      { value: null, text: '--SELECCIONAR MOTIVO NO ENTREGA--' },
      { value: '04_Producto deteriorado', text: '04_Producto deteriorado' },
      { value: '05_Sin dinero', text: '05_Sin dinero' },
      { value: '06_Cerrado', text: '06_Cerrado' },
      { value: '09_Ausencia Encargado Local', text: '09_Ausencia Encargado Local' },
      { value: '10_Sobre stock', text: '10_Sobre stock' },
      { value: '11_Diferencia en condición de pago', text: '11_Diferencia en condición de pago' },
      { value: '12_Problema de fecha', text: '12_Problema de fecha' },
      { value: '13_Dificultad en ruta', text: '13_Dificultad en ruta' },
      { value: '14_Duplicado/Mal digitado', text: '14_Duplicado/Mal digitado' },
      { value: '18_Sin orden de compra', text: '18_Sin orden de compra' },
      { value: '20_Horario inadecuado', text: '20_Horario inadecuado' },
      { value: '23_Fuera de ruta', text: '23_Fuera de ruta' },
      { value: '24_Exceso de clientes', text: '24_Exceso de clientes' },
      { value: '25_Envase', text: '25_Envase' },
      { value: '26_Falta de producto', text: '26_Falta de producto' },
      { value: '28_Carga sin salir', text: '28_Carga sin salir' },
      { value: '66_Capacidad Cliente', text: '66_Capacidad Cliente' },
      { value: '93_Diferencia de precio', text: '93_Diferencia de precio' },
      { value: '94_Entrega Atrasada', text: '94_Entrega Atrasada' },
      { value: '95_Local No Encontrado', text: '95_Local No Encontrado' },
      { value: '99_Cliente No visitado', text: '99_Cliente No visitado' }
    ]
  },
  getters: {
    Gettertoken (state) {
      return state.token
    }
  },
  mutations: {
    set_token (state, autorizacion) {
      state.token = autorizacion.token
      state.user = autorizacion.user
      state.password = autorizacion.password
    },
    set_accion_imagenUsuario (state, imagenUsuario) {
      state.imagenUsuario = imagenUsuario
    },
    set_accion_perfilUsuario (state, perfilUsuario) {
      state.perfilUsuario = perfilUsuario
      state.imagenUsuario = perfilUsuario.imagen_usuario_base64
    },
    set_accion_usuarios (state, usuarios) {
      state.usuarios = usuarios
    },
    set_accion_usuariosUpdate (state, usuariosUpdate) {
      state.usuariosUpdate = usuariosUpdate
    },
    set_accion_periodoEntregaDiaria (state, periodoEntregaDiaria) {
      state.periodoEntregaDiaria = periodoEntregaDiaria
    }
  },
  actions: {
    async GetToken ({ commit, dispatch, state }, credenciales) {
      const autorizacion = {}
      try {
        const response = await axios.post(`${store.state.hostGateway}/api/login/auth/iniciarSesion`, credenciales)
        // const response = await axios.post(`http://${store.state.host}/api/auth/iniciarSesion`, credenciales)
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff')
          autorizacion.token = response.data.tokenDeAcceso
          autorizacion.user = credenciales.usernameOrEmail
          autorizacion.password = credenciales.password
          commit('set_token', autorizacion)
          dispatch('getPerfilUsuario', credenciales.usernameOrEmail).then((res) => {
            console.log('cargado GetRol set_accion_perfilUsuario')
            router.push({ path: 'menu' })
          })
          // window.location.href = 'http://www.google.com'
          // router.push({ path: 'menu' })
          return true
        } else {
          autorizacion.user = ''
          autorizacion.password = ''
          autorizacion.token = 'clave o usuario invalido'
          commit('set_token', autorizacion)
          console.log('por else')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch')
        autorizacion.user = ''
        autorizacion.password = ''
        autorizacion.token = 'clave o usuario invalido'
        commit('set_token', autorizacion)
        return false
      }
    },
    async getImagenUsuario ({ commit, dispatch, state }, usuario) {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.hostGateway}/api/login/files/getImagenUsuario?user=${usuario}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getImagenUsuario')
          // console.log(response.data)
          commit('set_accion_imagenUsuario', response.data)
          return true
        } else {
          console.log('por else getImagenUsuario')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getImagenUsuario')
        router.push({ path: '/' })
        return false
      }
    },
    async getPerfilUsuario ({ commit, dispatch, state }, usuario) {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.hostGateway}/api/login/auth/getPerfil?emailOrUser=${usuario}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getPerfilUsuario')
          // console.log(response.data)
          commit('set_accion_perfilUsuario', response.data)
          return true
        } else {
          console.log('por else getPerfilUsuario')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getPerfilUsuario')
        router.push({ path: '/' })
        return false
      }
    },
    async getUsuarios ({ commit, dispatch, state }, groupId) {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.hostGateway}/api/login/auth/getUsuariosGrupo?group_id=${groupId}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getUsuarios')
          // console.log(response.data)
          commit('set_accion_usuarios', response.data)
          return true
        } else {
          console.log('por else getUsuarios')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getUsuarios')
        router.push({ path: '/' })
        return false
      }
    },
    async setUsuario ({ commit, dispatch, state }, usuario) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.hostGateway}/api/login/auth/registrar`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: usuario
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api setUsuario')
          // console.log(response.data)
          commit('set_accion_usuariosUpdate', response)
          commit('set_accion_usuarios', response.data)
          return true
        } else {
          console.log('por else setUsuario')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch setUsuario')
        commit('set_accion_usuariosUpdate', err)
        // commit('set_accion_usuarios', response.data)
        // router.push({ path: '/' })
        return false
      }
    },
    async deleteUsuario ({ commit, dispatch, state }, username) {
      try {
        const response = await axios({
          method: 'delete',
          url: `${store.state.hostGateway}/api/login/auth/delete?username=${username}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) {
          console.log('success stuff api deleteUsuario')
          commit('set_accion_usuariosUpdate', response)
          commit('set_accion_usuarios', response.data)
          return true
        } else {
          console.log('por else deleteUsuario')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch deleteUsuario')
        commit('set_accion_usuariosUpdate', err)
        return false
      }
    },
    setPeriodoEntregaDiaria ({ commit, dispatch, state }, periodo) {
      commit('set_accion_periodoEntregaDiaria', periodo)
    },
    async updateUsuario ({ commit, dispatch, state }, usuario) {
      try {
        const response = await axios({
          method: 'put',
          url: `${store.state.hostGateway}/api/login/auth/update`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: usuario
        })
        if (response.status === 200) {
          console.log('success stuff api updateUsuario')
          commit('set_accion_usuariosUpdate', response)
          commit('set_accion_usuarios', response.data)
          return true
        } else {
          console.log('por else updateUsuario')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateUsuario')
        commit('set_accion_usuariosUpdate', err)
        return false
      }
    }
  },
  modules: {
    EntregaDiaria,
    DocumentosPedido,
    Comentarios,
    Clientes,
    MotivoNoEntrega,
    Reportes
  }
})
