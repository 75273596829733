import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    entregaDiaria: [],
    entregaUpdate: {},
    entregaDiariaPage: []
    // motivoNoEntregaInicialObject: []
  },
  mutations: {
    set_accion_entregaDiaria (state, entregaDiaria) {
      state.entregaDiaria = entregaDiaria
    },
    set_accion_entregaUpdate (state, entregaUpdate) {
      state.entregaUpdate = entregaUpdate
    },
    set_accion_entregaDiariaPage (state, entregaDiariaPage) {
      state.entregaDiariaPage = entregaDiariaPage
    }
  },
  actions: {
    async getEntregaDiaria ({ commit, dispatch, state }, periodos) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/bitacora/entregaDiaria/all`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: periodos
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api getEntregaDiaria')
          // console.log(response.data)
          commit('set_accion_entregaDiaria', response.data)
          return true
        } else {
          console.log('por else getEntregaDiaria')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getEntregaDiaria')
        router.push({ path: '/' })
        return false
      }
    },
    async getEntregaDiariaPage ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/bitacora/entregaDiaria/allpage?page=${data.page}&size=${data.size}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data.periodos
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api getEntregaDiariaPage')
          // console.log(response.data)
          commit('set_accion_entregaDiariaPage', response.data)
          return true
        } else {
          console.log('por else getEntregaDiariaPage')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getEntregaDiariaPage')
        router.push({ path: '/' })
        return false
      }
    },
    async getFilterPage ({ commit, dispatch, state }, data) {
      try {
        // console.log(data.filter)
        // console.log(data)
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/bitacora/entregaDiaria/FilterPage?page=${data.page}&size=${data.size}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data.filter
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api getFilterPage')
          // console.log(response.data)
          commit('set_accion_entregaDiariaPage', response.data)
          return true
        } else {
          console.log('por else getFilterPage')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getFilterPage')
        // router.push({ path: '/' })
        return false
      }
    },
    async actualizaEntregaDiariaMotivoNoEntrega ({ commit, dispatch, state }, entrega) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/bitacora/entregaDiaria/updateMotivoNoEntrega`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: entrega
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api actualizaEntregaDiaria')
          console.log(response.data)
          commit('set_accion_entregaDiaria', response.data)
          return true
        } else {
          console.log('por else actualizaEntregaDiaria')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch actualizaEntregaDiaria')
        router.push({ path: '/' })
        return false
      }
    },
    async setEntregaDiaria ({ commit, dispatch, state }, entrega) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/bitacora/entregaDiaria/insert`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: entrega
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api setEntregaDiaria')
          // console.log(response.data)
          commit('set_accion_entregaDiaria', response.data)
          return true
        } else {
          // console.log('por else setEntregaDiaria')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch setEntregaDiaria')
        router.push({ path: '/' })
        return false
      }
    },
    async setEntregaDiariaAsync ({ commit, dispatch, state }, entrega) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/bitacora/entregaDiaria/insertAsync`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: entrega
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api setEntregaDiariaAsync')
          // console.log(response.data)
          commit('set_accion_entregaDiaria', response.data)
          return true
        } else {
          // console.log('por else setEntregaDiariaAsync')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch setEntregaDiariaAsync')
        router.push({ path: '/' })
        return false
      }
    },
    async updateEntregaDiariaAsync ({ commit, dispatch, state }, entrega) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/bitacora/entregaDiaria/updateEntregaDiariaAsync`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: entrega
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api updateEntregaDiariaAsync')
          // console.log(response.data)
          commit('set_accion_entregaDiaria', response.data)
          return true
        } else {
          console.log('por else updateEntregaDiariaAsync')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateEntregaDiariaAsync')
        router.push({ path: '/' })
        return false
      }
    },
    async updatePedidosItemsAsync ({ commit, dispatch, state }, entrega) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/bitacora/entregaDiaria/updatePedidosItemsAsync`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: entrega
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api updateEntregaDiariaAsync')
          // console.log(response.data)
          commit('set_accion_entregaDiaria', response.data)
          return true
        } else {
          console.log('por else updatePedidosItemsAsync')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updatePedidosItemsAsync')
        // router.push({ path: '/' })
        return false
      }
    },
    async updateSpmkAsync ({ commit, dispatch, state }, entrega) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/bitacora/entregaDiaria/updateSpmkAsync`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: entrega
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api updateEntregaDiariaAsync')
          // console.log(response.data)
          commit('set_accion_entregaDiaria', response.data)
          return true
        } else {
          console.log('por else updateSpmkAsync')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateSpmkAsync')
        // router.push({ path: '/' })
        return false
      }
    },
    async updateEstado ({ commit, dispatch, state }, estado) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/bitacora/entregaDiaria/updateEstado`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: estado
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api updateEstado')
          // console.log(response.data)
          commit('set_accion_entregaUpdate', response.data)
          return true
        } else {
          console.log('por else updateEstado')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateEstado')
        router.push({ path: '/' })
        return false
      }
    },
    async updatePlanificacionAsync ({ commit, dispatch, state }, planificaciones) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/bitacora/entregaDiaria/updatePlanificacionAsync`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: planificaciones
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api updatePlanificacionAsync')
          // console.log(response.data)
          // commit('set_accion_entregaDiaria', response.data)
          return true
        } else {
          console.log('por else updatePlanificacionAsync')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updatePlanificacionAsync')
        router.push({ path: '/' })
        return false
      }
    },
    async updateNroCamion ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/bitacora/entregaDiaria/updateNroCamion?nro_camion=${data.nro_camion}&id_transporte=${data.id_transporte}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api updatePlanificacionAsync')
          // console.log(response.data)
          // commit('set_accion_entregaDiaria', response.data)
          return true
        } else {
          console.log('por else updateNroCamion')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateNroCamion')
        router.push({ path: '/' })
        return false
      }
    },
    async getUpApi ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/bitacora/entregaDiaria/test`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          return true
        } else {
          console.log('por else getUpApi')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getUpApi')
        return false
      }
    }
  },
  getters: {
  }
}
