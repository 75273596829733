import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    documentosPedido: []
  },
  mutations: {
    set_accion_documentosPedido (state, documentosPedido) {
      state.documentosPedido = documentosPedido
    }
  },
  actions: {
    async getDocumentosPedido ({ commit, dispatch, state }, idDocumento) {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/documentos/transporte/${idDocumento}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getDocumentosPedido')
          console.log(response.data)
          commit('set_accion_documentosPedido', response.data)
          return true
        } else {
          console.log('por else getDocumentosPedido')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getDocumentosPedido')
        router.push({ path: '/' })
        return false
      }
    },
    async getDocumentosPedidoByIdTransporte ({ commit, dispatch, state }, idTransporte) {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/documentos/transporteID/${idTransporte}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getDocumentosPedidoByIdTransporte')
          console.log(response.data)
          commit('set_accion_documentosPedido', response.data)
          return true
        } else {
          console.log('por else getDocumentosPedidoByIdTransporte')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getDocumentosPedidoByIdTransporte')
        router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
