import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    comentarios: [],
    comentarioInsertado: [],
    comentarioDetalle: []
  },
  mutations: {
    set_accion_comentarios (state, comentarios) {
      state.comentarios = comentarios
    },
    set_accion_comentariosDetalle (state, comentarioDetalle) {
      state.comentarioDetalle = comentarioDetalle
    }
  },
  actions: {
    async getcomentariosByDocumento ({ commit, dispatch, state }, idDocumento) {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/comentarios/documento/${idDocumento}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getcomentariosByDocumento')
          console.log(response.data)
          commit('set_accion_comentarios', response.data)
          return true
        } else {
          console.log('por else getcomentariosByDocumento')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getcomentariosByDocumento')
        router.push({ path: '/' })
        return false
      }
    },
    async getcomentariosByIdEntregaDiaria ({ commit, dispatch, state }, idEntregaDiaria) {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/comentarios/entrega/${idEntregaDiaria}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api getcomentariosByIdEntregaDiaria')
          // console.log(response.data)
          commit('set_accion_comentarios', response.data)
          return true
        } else {
          console.log('por else getcomentariosByIdEntregaDiaria')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getcomentariosByIdEntregaDiaria')
        router.push({ path: '/' })
        return false
      }
    },
    async getcomentariosByTransporte ({ commit, dispatch, state }, idTransporte) {
      store.state.comentarios = []
      console.log(idTransporte)
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/comentarios/transporte/${idTransporte}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getcomentariosByTransporte')
          console.log(response.data)
          commit('set_accion_comentarios', response.data)
          return true
        } else {
          console.log('por else getcomentariosByTransporte')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getcomentariosByTransporte')
        router.push({ path: '/' })
        return false
      }
    },
    async getcomentariosAll ({ commit, dispatch, state }, idTransporte) {
      store.state.comentarios = []
      console.log(idTransporte)
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/comentarios/all`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getcomentariosAll')
          console.log(response.data)
          commit('set_accion_comentarios', response.data)
          return true
        } else {
          console.log('por else getcomentariosAll')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getcomentariosAll')
        router.push({ path: '/' })
        return false
      }
    },
    async getcomentariosAllByPeriodo ({ commit, dispatch, state }, periodo) {
      store.state.comentarios = []
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/comentarios/all`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: periodo
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api getcomentariosAllByPeriodo')
          // console.log(response.data)
          commit('set_accion_comentarios', response.data)
          return true
        } else {
          console.log('por else getcomentariosAllByPeriodo')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getcomentariosAllByPeriodo')
        router.push({ path: '/' })
        return false
      }
    },
    async insertComentario ({ commit, dispatch, state }, comentario, idDocumento) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/comentarios/insert/documento/${idDocumento}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: comentario
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api getcomentariosAll')
          // console.log(response.data)
          commit('set_accion_comentarios', response.data)
          return true
        } else {
          console.log('por else getcomentariosAll')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getcomentariosAll')
        router.push({ path: '/' })
        return false
      }
    },
    async insertComentarioGeneral ({ commit, dispatch, state }, comentario) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/comentarios/insert/transporte/${comentario.idTransporte}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: comentario.comentario
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api getcomentariosAll')
          // console.log(response.data)
          commit('set_accion_comentarios', response.data)
          return true
        } else {
          console.log('por else getcomentariosAll')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getcomentariosAll')
        router.push({ path: '/' })
        return false
      }
    },
    async insertComentarioDetalle ({ commit, dispatch, state }, comentario) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/bitacora/comentarios/update/${comentario.id}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: comentario.comentario
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api insertComentarioDetalle')
          // console.log(response.data)
          commit('set_accion_comentariosDetalle', response.data)
          return true
        } else {
          console.log('por else insertComentarioDetalle')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch insertComentarioDetalle')
        router.push({ path: '/' })
        return false
      }
    },
    async getComentariosByNroTransporteAndNroCliente ({ commit, dispatch, state }, comentario) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/bitacora/comentarios/transporte/${comentario.nro_transporte}/cliente/${comentario.nro_cliente}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api getComentariosByNroTransporteAndNroCliente')
          // console.log(response.data)
          // commit('set_accion_comentariosDetalle', response.data)
          return response.data
        } else {
          console.log('por else getComentariosByNroTransporteAndNroCliente')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getComentariosByNroTransporteAndNroCliente')
        // router.push({ path: '/' })
        return false
      }
    },
    async updateComentario ({ commit, dispatch, state }, comentario) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/comentarios/update/comentario`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: comentario
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api getcomentariosAll')
          // console.log(response.data)
          // commit('set_accion_comentarios', response.data)
          return true
        } else {
          console.log('por else updateComentario')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateComentario')
        router.push({ path: '/' })
        return false
      }
    },
    async updateComentarioDetalle ({ commit, dispatch, state }, comentario) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/bitacora/comentarios//update/comentario_detalle`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: comentario
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api getcomentariosAll')
          // console.log(response.data)
          // commit('set_accion_comentarios', response.data)
          return true
        } else {
          console.log('por else updateComentarioDetalle')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateComentarioDetalle')
        router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
